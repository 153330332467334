import { convertAttributesInElement } from '@storyblok/react'
import {
  richTextResolver,
  type StoryblokRichTextNode,
} from '@storyblok/richtext'
import { createElement, type ReactElement } from 'react'

import { type StoryblokRichtextType } from './model/storyblokTypes.generated'

// Source https://github.com/storyblok/storyblok-react/blob/5f13579d6196ebf3927756f2abb0ac99fcf057b5/src/storyblok-rich-text.tsx

const { render } = richTextResolver<ReactElement>({
  keyedResolvers: true,
  renderFn: createElement,

  /**
   * By default, HTML entities are encoded.
   * See https://github.com/storyblok/richtext/blob/d1311ae166eb1a63ee5cc08099bae0c022fcf5d6/src/richtext.ts#L38
   *
   * React takes care of HTML entities. Prevent text encoding.
   * See https://github.com/storyblok/richtext/blob/d1311ae166eb1a63ee5cc08099bae0c022fcf5d6/src/richtext.ts#L147-L163
   */
  textFn: (text) => text as unknown as ReactElement,
})

/**
 * Use {@link useCmsRichTextRender} instead of this function.
 */
export const cmsRenderRichText = (
  richText: StoryblokRichtextType,
): ReactElement | ReactElement[] =>
  convertAttributesInElement(
    render(richText as StoryblokRichTextNode<ReactElement>),
  )
